/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';

:root{
  //--blue:#155ba4;
  --blue:#1268b5;
  --red:#ff4c3b;
  --br-active-color: #FF9A00 !important;

  //  #1c81ea   hover color
}

.blue_txt{color:var(--blue)}
//decleration variable var(--blue);

.setting-sidebar, .dark-light{display: none}

.promo1 {
  background: #5a88ca;
}
.promo2 {
  background: #ffd900;
}
.promo3 {
  background: salmon;
}
.promo4 {
  background: #4abebd;
}
.promo5 {
  background:#383939;
}
.promo6 {
  background:#f90;
}

.single-promo {
  font-size: 25px;
  font-weight: 200;
  padding: 20px 10px;
  text-align: center;

  p {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 0px;
    text-shadow: 1px 1px 3px #000;
  }

  i {
    font-size: 50px;
    color: #fff;
  }
}

.promo-area {
  color: #fff;
}

.single-product{background:#fff; box-shadow:1px 0px 12px #dadada;padding:10px;margin-bottom:15px;
  img{height:150px;    width:180px;}
  h4{font-weight:600}
}

.part-1{  align-items: center; justify-content: center;  display: flex;height:230px}
.part-2{ display: grid; align-items: center;justify-content: center;height:120px}
.parallax-banner1{ background: url("assets/images/bg.JPG"); background-size: cover;background-position: center;background-attachment: fixed}
.cust_tabs{float:none; display: table;margin:0 auto;
  li{padding-left: 25px;padding-right: 25px;}
  li a{text-transform: uppercase;font-weight:bold;font-size:20px}
  li a.active{color:#ff443b !important; background-color: transparent !important;font-weight:bold;font-size:18px}
}

//.home-slider .home, .center-slider .home{background-size:contain}

.main-menu .brand-logo{padding-top:25px !important;padding-bottom:25px !important;}
header .sidenav .sidebar-menu li .mega-menu .link-section ul li a {
  padding: 5px 0px 5px 10px !important;}
// header .sidenav .sidebar-menu li .mega-menu{display:none}
header .sidenav nav{overflow-x: hidden;}
header .sidenav .sidebar-menu li ul{padding:8px 0px}
header .sidenav .sidebar-menu{padding-bottom:50px;}
header .main-navbar .nav-menu > li .nav-submenu{padding: 10px 0px 10px 0px;}
//header .main-navbar .nav-menu > li .nav-submenu{width: 400px;height: 400px;  display: flex;flex: wrap}



// my style start

header .sidenav .sidebar-menu li ul {
  position: relative !important;
  width: 100% !important;
  left: 0 !important;}
header .sidenav .sidebar-menu li a {
  padding: 10px 25px;
  font-weight: 700;
}
// my style End


// contact us

.svg_type {  position: relative;background: url("assets/images/bg.JPG");background-attachment: scroll;
  background-size: auto;background-size: cover;background-position: center;background-attachment: fixed;
  .row::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;  bottom: 0;
    background: #000000c9;
    position: absolute;
  }

  svg{width:80%}
  .pos_svg{
    width:300px; text-align: center;position:absolute; left:29%; top:32%;
    h3{font-size: 25px;font-weight: bold;  color: #fff; text-shadow: 2px 1px 3px #200707;}
    p{color: #fff;  font-weight: bold; text-shadow: 1px 1px 2px #240303;  font-size: 16px;}
  }
}


.domestic{ padding-top: 0;
  .left{
    h3{color:#f00;padding-bottom: 10px;}
    p{color:#000;font-size: 20px;}
    i{color: #ff000082;font-size: 20px;background: #fff;border-radius: 50%;padding: 10px;
      width: 30px;height: 30px;line-height: 12px;box-shadow: 0px 0px 5px #d0caca;text-align: center;}
  }
  .right{
    h3{color:#c11e1e;padding-bottom: 10px;}
    p{color:#000;font-size: 20px;}
    i{color: #ff000082;font-size: 20px;background: #fff;border-radius: 50%;padding: 10px;
      width: 30px;height: 30px;line-height: 12px;box-shadow: 0px 0px 5px #d0caca;text-align: center;}
  }

}

header .main-navbar .nav-menu > li > a{ padding-right:25px}

.main_cat img{width:auto;height:110px}
header .main-navbar .nav-menu > li > a .sub-arrow {right: 5px;}

.top-header .header-contact { padding:5px 0;}
.top-header .header-dropdown li{padding:5px 25px;}
header .main-navbar .nav-menu > li .nav-submenu li a{font-size:14px !important;padding: 2px 35px;}
header .main-navbar .nav-menu > li:nth-child(3) > a{margin-right: 15px;}
header .main-navbar .nav-menu > li:nth-child(4) > a{margin-right: 15px;}
.mobile-setting{display: none;}
.strick_text{text-decoration: line-through;}
.collection-banner:hover img{transform: scale(1.2);}
.main_cat img { height: 120px;}


header .main-navbar .nav-menu > li .nav-submenu li{  border: 0;
  float: none;
  position: relative;
  display: block;
  text-align: center;
  justify-content: left;
  display: flex;
}

.product-box h6, .product-box .h6{line-height:24px !important;}
.product-box .img-fluid{ height:120px !important;}
.owl-carousel .product-box .img-fluid{ height: auto !important;  width: auto;}
.theme-tab .product-box .img-fluid{ height: 200px !important;  width: 200px;}

#mainnav .nav-menu li:nth-child(4) .nav-submenu{width:500px;flex-wrap: wrap}
#mainnav .nav-menu li:nth-child(4) .nav-submenu li{width:50%;float:left}
.top-header .header-contact li{font-size: 14px;padding-right: 20px;}
//.top-header{background-color:#ffa700}
.top-header{background-color:#f00}
.top-header .header-contact li, .top-header a, .top-header .header-contact li i,
.top-header .header-dropdown li{color:#fff !important;font-weight:bold}
.top-header .header-dropdown li a i, .top-header .header-contact li i,
.top-header .header-dropdown li i{color:#f00 !important}
.onhover-show-div li a{color:#000 !important;}
.onhover-show-div{box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;}
header .sidenav .sidebar-menu li a{color:var(--blue);}
header .sidenav .sidebar-menu li .mega-menu li .link-section h5, header .sidenav .sidebar-menu li .mega-menu li .link-section .h5{color:red}
header .sidenav .sidebar-menu li .mega-menu .link-section ul li a{color: #000;}
header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a::before {display:none}

.sidebar-back text-start{opacity: 0;margin-bottom: 30px !important;height: 0px;margin: 0;padding-bottom: 20px;}
.owl-next{height:25px;width: 25px;position:absolute;right:0;line-height: 18px;padding: 4px !important;border-radius: 50% !important;}
.owl-prev{height:25px;width: 25px;position:absolute;left:0;line-height: 18px;padding: 4px !important;border-radius: 50% !important;}
.owl-theme .owl-nav [class*="owl-"]:hover {background: #ff0000c4;-webkit-transition: all 0.3s ease;  -moz-transition: all 0.3s ease;  -ms-transition: all 0.3s ease;  -o-transition: all 0.3s ease;  transition: all 0.3s ease;}
.owl-theme .owl-nav [class*="owl-"] {background: #ff00003d;-webkit-transition: all 0.3s ease;  -moz-transition: all 0.3s ease;  -ms-transition: all 0.3s ease;  -o-transition: all 0.3s ease;  transition: all 0.3s ease;}

.product-detail  h6{ display: -webkit-box;  -webkit-line-clamp: 2;-webkit-box-orient: vertical;  overflow: hidden;min-height: 50px;}
.product-detail  h6{ display: -webkit-box;  -webkit-line-clamp: 2;-webkit-box-orient: vertical;  overflow: hidden;min-height: 50px;}
.owl-item .product-box .img-fluid { height: 92px !important;width: 140px !important;}


//header correction start

.main-menu .menu-left .navbar { padding: 0px 45px 0px 0;}
.onhover-div{padding-top:0;padding-bottom: 0}
.main-menu .brand-logo{padding-top:0 !important;padding-bottom: 0 !important;}
header .main-navbar .nav-menu > li > a .sub-arrow{right:-10px}
header .main-navbar .nav-menu > li > a{color:var(--red) !important; font-weight: bold;padding-top:10px; padding-bottom: 10px;
  margin-left: 3px !important;margin-right: 3px !important;padding-left:10px !important;padding-right:10px !important}
.pad_set{padding-top:20px; padding-bottom:5px}
.main-menu .menu-right .icon-nav li{padding-top:10px}

.top-header .header-contact li, .top-header a, .top-header .header-contact li i,
.top-header .header-dropdown li {color:#fff !important;  font-weight: bold;}
.onhover-dropdown .onhover-show-div {top: 32px;}
header .main-navbar .nav-menu > li .nav-submenu li a::before {display:none}
.fa.fa-phone{color:#00ff9f !important}
//header correction End


.collection-collapse-block .collapse-block-title{color:var(--red)}
.collection-collapse-block .collection-collapse-block-content
.collection-brand-filter .collection-filter-checkbox label, .collection-collapse-block .collection-collapse-block-content
.collection-brand-filter .category-list li a{color:var(--blue); font-weight: bold}
header .main-navbar .nav-menu > li > a {color: var(--red) !important;}
.header-contact li{font-size:14px }

.small_img{height:40px}

.top-header .header-contact li {padding-right: 8px !important;padding-left: 8px !important;border-right:1px dotted #fff}
.top-header .header-contact li:last-child{border:0}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label, .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li a{font-size:14px !important;}
.sidebar-back.text-start{height:0; opacity:0}
.theme-tab .tab-title a.nav-link, .theme-tab .tab-title2 a.nav-link{color:var(--blue);font-weight: bold}
.strick_text{color:#f00}
.product-detail h5{ font-weight:bold;color:var(--blue)}
.hight_400{height:320px;display:flex}
.hight_400 .main_img{align-self:center}
.rate_txt{font-size:20px;font-weight:bold}
.text_light{color:#808080}
[_nghost-serverApp-c116] {  --br-active-color: #FF9C05 !important;}

.table > thead > tr > th {vertical-align: middle;}
.rating_inn{background: #388e3c;  color: #fff;  font-weight: bold;  border-radius: 4px;  padding: 2px 4px;
  width:45px;text-align: center;}
.rating_inn i{font-size: 12px}
.collection-filter-block h4{color:var(--red);font-weight:bold}
.collection-filter-block p{color:var(--blue);font-weight:bold}
.collection-filter-block {margin-bottom: 30px;padding: 0 16px;}
.border_dot{border-bottom:1px dotted #808080}
.tab-product .nav-item a {font-weight: bold;color:var(--blue) !important;}
.tab-product .nav-item a.active {font-weight: bold;color:var(--red) !important;}
.rating_modal .hit_200{height:200px !important;font-size:14px}
.rating_modal .popup_rating{align-items: center; display: flex;  margin-bottom: 0;color:var(--blue);font-weight:bold}
.rating_modal .br-default .br-unit {font-size: 40px !important;}
.rating_modal .rating-sec{  padding-bottom: 0px;position: relative;  top: -7px;}
.rating_modal .form-control {height: 38px;}
.rating_modal input{font-size:14px !important}
.rating_modal .btn-outline-primary{margin-top:10px;}
.tab-content h5{color:var(--blue);font-weight:bold}
.btn-outline-primary{margin-top:10px;}
.close{border: 1px solid red;width: 28px;height: 28px;border-radius: 50%;
  background: #fff;  color: red; font-weight: bold;font-size: 20px;  line-height: 9px;}
.main-menu .menu-left .navbar i{color:var(--red)}
.mobile-wishlist{display:none}
.mobile-account{padding-left:10px !important;}
.onhover-show-div li a{color:var(--blue) !important}
header .main-navbar .nav-menu > li .nav-submenu li a{color:var(--blue) !important}
.main-navbar .nav-menu .menu_active{background: var(--red);color: #fff !important; border-radius: 5px;}
.logo-block .w-auto{width:150px !important; height:100px !important;}

.main_bran{margin:0 auto;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;padding:50px 100px;
  margin-bottom:50px}
.box_pos{box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;padding: 20px;position: relative;
  background: var(--blue);left: 12px;height: 110%;top:-10%;box-shadow: 0px 9px 15px #919090;
  p {color:#fff;font-size:25px;}
  h3{padding-bottom: 8px;border-bottom: 1px solid #ffffff59;color:#fff;text-shadow: 0px 2px 5px #000;
    padding-bottom:10px;margin-bottom: 20px;padding-left:15px;}
  .fa.fa-mobile{background:#0b4b84;    font-size: 25px;
    width: 40px;    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #fff;}
}
.box_pos_two
{box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  padding: 20px;height: 110%;position: relative;
  left: -12px;background:var(--blue);color: #fff !important;top:-10%;box-shadow: 0px 9px 15px #919090;
  //#e84a4a
  p {color:#fff;font-size:25px;}
  //text-shadow: 3px 1px 5px #000
  h3{color: #fff;padding-bottom: 8px; border-bottom: 1px solid #ffffff59;padding-bottom:10px;
    margin-bottom: 20px;text-shadow: 0px 2px 5px #000;}
  .fa.fa-mobile{background:#0b4b84;    font-size: 25px;
    width: 40px;    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #fff;}
}

.bord_btmset{border-bottom: 1px dotted #bfbfbf;padding-bottom: 23px;}
.rfh_bran h3{color:var(--blue);font-weight:bold;padding-bottom: 8px}
.rfh_bran p b{color:var(--red);font-size:20px}

.contact_us{background: #fdfdfd}

.invalid-feedback{position: relative;  top: -7px;}
.center_img .tab-pane{text-align: center}

.ti-close{color: #f00;font-weight: bold;font-size: 18px;}
.search-count{width:100% !important;border-bottom: 1px solid #dadada}
.product-filter-content{border-right:1px solid #dadada;border-left: 1px solid #dadada}
.grid-layout-view{display: none}
.search-count h5{text-align: center}


.NewsLetterModal .modal-dialog {
  max-width: 97%;}

.NewsLetterModal .modal-dialog img{width: 100%}
.NewsLetterModal .modal-dialog { width: 100%; max-width:94%;}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close span {color: #f00 !important;}
.theme-modal .modal-dialog .modal-content .modal-body {background-image: inherit !important;}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close{font-size: 44px !important;}




.single-promo{margin-bottom:25px}
//.main_cat:first-child .single-promo{background:rgba(90, 136, 202, 0.06) !important;}
//.main_cat:nth-child(2) .single-promo{background: rgba(255, 217, 0, 0.14) !important   m;}
//.main_cat:nth-child(3) .single-promo{background:#fa807212 !important;}
//.main_cat:nth-child(4) .single-promo{background:rgba(74, 190, 189, 0.15) !important}
//.main_cat:nth-child(5) .single-promo{background:#7788990f !important;}
//.main_cat:nth-child(6) .single-promo{background:rgba(215, 234, 87, 0.11) !important}
//.main_cat:nth-child(7) .single-promo{background:rgba(255, 0, 0, 0.05) !important}
//.main_cat:nth-child(8) .single-promo{background:rgba(213, 255, 247, 0.33) !important;}
//.main_cat:nth-child(9) .single-promo{background:rgba(109, 94, 185, 0.04) !important;}
//.main_cat:nth-child(10) .single-promo{background:rgba(232, 40, 130, 0.05) !important;}
//.main_cat:nth-child(11) .single-promo{background:rgba(210, 198, 99, 0.2) !important;}
//.main_cat:nth-child(12) .single-promo{background:rgba(0, 199, 24, 0.11) !important;}

.single-promo p{padding-top:10px}
.product-right h4{ font-size:18px; font-weight:bold}
.product-right h3{font-weight:bold;color:var(--blue);padding-top:10px}
.offe_box td{ padding:3px 8px;color:#808080}
.offe_box h3{color: var(--red);font-weight:bold; text-align:left; font-size: 18px; padding: 8px 0px;text-transform: uppercase}
.offe_box{padding-top:10px; padding-bottom:0px}
.c_box{background: #fbfcfd;  padding: 50px 0px; margin-top: 40px;  box-shadow: 0px 1px 15px #b3aeae;}
.c_box h3{padding-top:30px;font-size:18px;font-weight:bold}
.c_box img{width: 90px; border-radius: 50%;padding: 9px;border: 4px solid #326edf3b;}
.jumbotron p{background: #f1f1f1;padding:20px;}
.new_ics img{ width:30px;height:30px !important;}
.new_ics ul{margin:0 auto; float:none; display:table;margin:0 auto}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media img{height: 70px;width: 90px;position: relative;top: 15px;}
.total h5, .red_txt{color: var(--red) !important;font-weight: bold;}
header{box-shadow: 0px 0px 4px #ddd;}
.in_box img{padding:10px;box-shadow: 0px 0px 10px #dadada;width: 100%;}
.cart-section .cart-table thead th, .wishlist-section .cart-table thead th{color: var(--blue);}
.cart-section tbody tr td .td-color, .wishlist-section tbody tr td:last-child .td-color
{font-weight: bold;font-size: 20px;color:#594444}
.cart-section tbody tr td:nth-child(3){font-size: 20px;color:#594444}
.cart-section tfoot tr td h2, .cart-section tfoot tr td .h2,
.wishlist-section tfoot tr td h2, .wishlist-section tfoot tr td .h2{color:var(--red)}
.payment_details{  box-shadow: 0px 0px 5px #dadada;padding: 20px;}
.payment_details td:first-child {color: #f00; font-weight: bold;font-size: 16px;padding:10px;width:50%}
.payment_details td:last-child {color:#000;font-weight: bold;font-size: 16px;padding:5px;width:50%}
.payment_details h3{color:#000;font-weight:bold}
.color_black td{color:#000}
.payment_table h3{color:#000; font-weight: bold;padding-bottom:10px}
.payment_table{ box-shadow: 0px 0px 5px #dadada;padding: 20px; margin-top: 20px}
.payment_table td{padding:10px 0px}
.cust_table th{background: #ff000012;padding: 15px;color:#f00}
.cust_table td{padding:15px}
//.cust_table {box-shadow:0px 0px 11px rgb(189, 189, 189);margin-top: 15px;border:0}
.cust_table {border: 1px solid #f2f0f0;}
.total_txt{color:var(--blue);font-size:18px; font-weight: bold}
.num_txt{font-size:20px; color:#f00;font-weight: bold}
.tfoot td{background:rgba(183, 153, 153, 0.07)}
.ind_row{background: rgba(255, 0, 0, 0.0705882353);}
.color_black{background:#dadada2e}
.color_black td{ color:var(--blue) !important}

.cust_search .form-control{height:30px}
.cust_search{margin-bottom: 0px !important;}
.home-slider .slider-contain, .home-slider .home {height: inherit !important}
.home-slider .slider-contain, .center-slider .slider-contain{height:inherit !important}
.input_type{float: left; margin-top: 5px;margin-right: 5px;}
.price_txt {color:var(--blue)}
.shopping-cart .media h4{ font-size:13px;line-height:16px }
.breadcrumb-section{display: none}
.onhover-div .show-div {top:35px;}
.filter-main-btn{display: none !important;}
.choice_text{font-weight: bold;font-size: 16px}
.slider-contain div{width:100%}
.padding_tb {padding-top:50px !important;padding-bottom:0px !important}
.pb_50{padding-bottom:50px !important;}
.pt_50{padding-top:50px !important;}
.title1 h4, .title1 .h4{font-weight:bold}
.new_ics ul{ margin:0 auto; float:none; display:table !important;text-align:center}

.owl-carousel .owl-item img {
  display: block;
  width:100%;
  margin: 0 auto;
}
.already_mem{float: right;padding: 8px 10px;margin-right: 0 !important;}

.btn-solid{padding: 8px 10px;border-radius: 3px}
.register-page .theme-card .theme-form input { font-size: 14px;padding:10px 20px;margin-bottom:15px}
.contact-page .theme-form input {font-size: 14px;padding: 10px 20px;margin-bottom:15px;}
select.form-control{height:44px}
.c_box h3{color:var(--blue)}
.red_txt{color:#f00}

.checkout-page .checkout-form .checkout-details {background-color:rgba(255, 0, 0, 0.04);border:0}
.checkout-page .checkout-form .form-group {margin-bottom:0}
.checkout-details th{ padding:8px 0px;color:#f00}
.checkout-details th:last-child{width:150px; text-align: right}
.checkout-details td:last-child{text-align: right}
.checkout-details td{ padding:8px 0px}
.font_total td{font-size: 20px;font-weight: bold}
.order-box { margin-bottom:20px}

#headingTwo .form-group{ margin-bottom: 10px}
#termsandconditionModal.modal {--bs-modal-width: 96%;}

.btn-success.btn-solid { color: #ffffff;  letter-spacing: 0.05em;  border: 2px solid #207514;  border-top-color: rgb(32, 117, 20);  border-right-color: rgb(32, 117, 20);  border-bottom-color: rgb(32, 117, 20);  border-left-color: rgb(32, 117, 20);
  background-image: linear-gradient(30deg, #318213 50%, transparent 50%);}
.btn-success.btn-solid:hover {color: #0a5c0a;}

textarea{height: 78px !important;padding:5px 10px !important;}
.font_18{font-size: 18px}
.del_btn{font-size: 14px; padding:5px 10px}
.blue_txt{color:var(--blue) !important;font-weight: bold}
select{font-size: 14px !important;}
.cart_qty_cls{cursor: pointer}
.ord_table th, .ord_table td{padding:10px}
.ord_table th{color:var(--blue);background: #f2f2f2}
.ord_table td{ font-size:13px}
.ord_table table{border-left:1px solid #dadada; border-right:1px solid #dadada}
#myordersviewModal.modal {--bs-modal-width:96%;}
.ord_table a{color:#f00}
.prod_txt{display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
  overflow: hidden; min-height: 55px;height:55px}
.del_title{font-size:20px; color:var(--blue); margin-bottom:5px; font-weight: bold}
.green_active{color:#207514 !important}
.filter_tag .ti-close{color:#fff}
.product-filter-tags li a.filter_tag {
  color: #fff;  border: 1px solid #f00;font-size: 13px;  background: #ff0000d4;
  font-weight: bold;}

ngx-loading-bar {
  color: var(--blue) !important;font-size:50px; width:50px;}

.compare{font-weight: bold;}
.compare i{padding-right:5px}
.modal-title{font-size: 18px;font-weight: bold;color: var(--blue)}
.main_image{ cursor: pointer;}

.product-box .cart-info.cart-wrap i, .product-box .cart-wrap.cart-wrap i, .product-wrap .cart-info.cart-wrap i, .product-wrap .cart-wrap.cart-wrap i {padding-bottom: 8px}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter {
  max-height: inherit !important;}
.collection-collapse-block.open .collection-collapse-block-content {
  max-height:inherit !important;}

.tab-content td:first-child{width:50%;padding:10px;color:#808080;font-weight:bold}
.tab-content td:last-child{border:0;border-bottom: 1px solid #dadada87;color:#000;font-weight:bold}
.tab-content .ti-check {color: #07c436;font-size: 20px;}
//.tab-content h5{color:#207514;}

td:first-child {}

.btn-solid:focus, .btn-solid:hover, .btn-solid:focus{color:#f00;border:1px solid #f00;color: #f00 !important;border: 2px solid #f00 !important;background:transparent !important;}
.product-box.aircooler_product .img-fluid {height: 200px !important;}
.product-box.heater_product .img-fluid {height:180px !important;}

.non_ac_product .hight_400 img{ width:60%; height: 100%}
.non_ac_product .hight_400 .small_img{ width:auto !important; height: 100%}
.btn_small_image .owl-item{ width:59px !important;height:50px;}

.eyeicon{position: absolute;right:14px; top:12px; cursor: pointer}
.eyeicon.error_eye{right:32px; }

.eyeicon i{font-size: 20px;color: #807e7e;}
.cursor_pointer{cursor: pointer;}
.btn_margin{margin-top: 30px;}

.compare_img{width:200px !important; height:100px !important;}
.remove-compare i{font-size: 30px; color:#f00}


.product_cust_img{ height:300px;text-align: center}
.product_cust_img img{ width:auto; height: 60%; cursor:pointer}

.sm_product{width: 70px;  height: 50px;  padding: 5px;  border: 1px solid #dadada;  margin-right: 5px;cursor: pointer}

.active_thumb{border:3px solid #f00;overflow: hidden}

.tab_in_pad td{ padding-right: 5px}
.tab_in_pad td input{margin-right: 0px !important;}


.modal-dialog .product-detail h6{color:var(--blue)}


.product-box .cart-info.cart-wrap i, .product-box .cart-wrap.cart-wrap i, .product-wrap .cart-info.cart-wrap i, .product-wrap .cart-wrap.cart-wrap i {
  padding-bottom: 0;
}

.CartModal h4{ color:var(--blue) !important}
.CartModal h6 a{ color:#777777 !important; font-weight: normal}


.product-box .cart-info.cart-wrap i, .product-box .cart-wrap.cart-wrap i, .product-wrap .cart-info.cart-wrap i,
.product-wrap .cart-wrap.cart-wrap i {  padding-top: 7px;}
#policytext p{margin-bottom: 5px;color:var(--blue);font-size: 16px;font-weight: bold;font-style: inherit !important;padding-top: 10px}
.cart-section tfoot tr td:first-child, .wishlist-section tfoot tr td:first-child{padding-top: 30px;padding-bottom: 30px}

.custom-bullet-list li{ list-style: star; width:98%;list-style-type: square; font-size: 16px;list-style: circle !important;
  display: list-item;margin-left: 16px;  padding: 7px 0px;  opacity: 0.7;}
.gal_img img{ width: 100%;transition: transform .5s ease-in-out;border-radius: 20px; height: 100%}
.gal_img{ overflow: hidden; padding:10px; cursor: pointer;}
.gal_img img:hover{ transform: scale(1.3);transition: transform .4s ease-in-out;}
li::marker {color:green;}

//.checkout-details li:first-child{width:60%}
//.checkout-details li:first-child{width:40%}

@media (min-width: 1200px) {
  header .main-navbar .nav-menu > li .nav-submenu { width: 230px; }
  header .sidenav .sidebar-menu li .mega-menu { padding-top:0 !important; padding-bottom:0 !important}
}


@media (max-width:1199px) {

  //.main-navbar .toggle-nav { padding-top: 0;}
  .cust_search{position: absolute;left:0; width: 100%;top:-55px}
  .pad_set { padding-top: 70px;}
}

@media (max-width:991px) {

  #mainnav .nav-menu li:nth-child(4) .nav-submenu{width:240px !important;flex-wrap: nowrap}
  #mainnav .nav-menu li:nth-child(4) .nav-submenu li{width:100% !important;float:left}
  .top-header .header-contact{display:block !important}
  .collection-filter { position: unset; height: auto;max-width: 100%;}
  .media_cr{text-align:center}
  .mobile_none{display: none}
  .collection-filter-block { margin-bottom: 0;}
  .main_bran{padding:20px !important;}
  .box_pos{left:0px}
  .box_pos_two{left:0}
  .media_remove{margin-top:0px !important;}
  #mainnav .nav-menu li:nth-child(4) .nav-submenu { width: 100% !important;}
  .product_cust_img {height: auto; padding-top: 30px; padding-bottom: 50px;}
  .collection-mobile-back{display: none}
  .qty-box{width:140px}

}

@media (max-width:767px) {
  .box_pos { left: 0;}
  .table_top td{display: block;width: 100%; text-align: center}
  .home-slider .slider-contain, .center-slider .slider-contain{height:auto !important;}
  .btn_margin{margin-top:10px !important;}






}
@media (max-width:480px) {
  .brand-logo img {
    height: auto;}
  .register-page h3, .register-page .h3{margin-bottom: 50px;}
  .btn-solid{ margin-bottom:5px}
}


.gst_table td input{
  padding: 0px !important;
  text-align: center;
  margin-right: 5px;
  border-radius: 5px;
  // background-color: red;
}

.uppercase {
  text-transform: uppercase;
}
